import { ReactNode } from 'react'
// material-ui
import { Box, Button, Typography, useTheme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { PersonAddAlt } from '@mui/icons-material'

import { useAppSelector, useAppDispatch } from '../../../../hooks/reduxHooks'
import { selectRole } from '../../../../stores/AuthStore/selectors'
import { UserRole } from '../../../../types/user'
import { BoxIcon, CatalogIcon, NotificationIcon } from '../../../../components/icons'
import { setProductFilters } from '../../../../stores/ProductsStore/productsSlice'
import { isQorpak } from '../../../../utils/common'
import { selectFeatureFlag } from '../../../../stores/UiStore/selectors'
import { trackNavigationMenu } from '../../../../utils/segment'
import { FeatureFlags } from '../../../../constants/feature-flag'

// ==============================|| MAIN NAVBAR / HEADER ||============================== //
const NavButton = ({ text, icon, onClick }: { text: string; icon: ReactNode; onClick: () => void }) => {
  const theme = useTheme()

  return (
    <Button
      onClick={onClick}
      startIcon={icon}
      sx={{
        p: '4px',
        color: theme.palette.common.black,
        '&:hover, &:focus': {
          background: 'none',
        },
        '.MuiButton-startIcon': {
          ml: 0,
          mr: '4px',
        },
      }}
    >
      <Typography
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          textTransform: 'none',
          whiteSpace: 'nowrap',
        }}
      >
        {text}
      </Typography>
    </Button>
  )
}

type Props = {
  shouldDisplayCatalog: boolean
  catalogHref?: string
}
const NavSection = ({ shouldDisplayCatalog = false, catalogHref }: Props) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const userRole = useAppSelector(selectRole)
  const notificationEnabled = useAppSelector(selectFeatureFlag(FeatureFlags.notifications))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '16px',
      }}
    >
      {userRole === UserRole.EMPLOYEE ? (
        <>
          <NavButton
            onClick={() => {
              navigate('/')
              trackNavigationMenu('Orders')
            }}
            icon={<BoxIcon />}
            text="Orders"
          />
          <NavButton
            onClick={() => {
              navigate('/employee/user-management')
              trackNavigationMenu('User Management')
            }}
            icon={<PersonAddAlt sx={{ color: theme.palette.common.black }} />}
            text="User Management"
          />
        </>
      ) : (
        <NavButton
          onClick={() => {
            navigate('/orders')
            trackNavigationMenu('Orders')
          }}
          icon={<BoxIcon />}
          text="Orders"
        />
      )}
      {shouldDisplayCatalog && (
        <NavButton
          onClick={() => {
            // clear filters first before navigating to prevent API being triggered twice
            dispatch(setProductFilters({ filters: {}, searchString: '', page: 1, perPage: 12, sortBy: 'NONE' }))
            if (catalogHref && location.pathname !== catalogHref) {
              isQorpak() ? window.open(catalogHref, '_blank') : navigate(catalogHref)
            }
            trackNavigationMenu('My Products')
          }}
          icon={<CatalogIcon />}
          text="My Products"
        />
      )}
      {notificationEnabled && (
        <NavButton
          onClick={() => {
            navigate('/notifications')

            // Segment tracking
            trackNavigationMenu('My Notifications')
          }}
          icon={<NotificationIcon />}
          text="My Notifications"
        />
      )}
    </Box>
  )
}

export default NavSection
