import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationConfig, NotificationOrderFilter, NotificationTemplate } from '../../types/notifications'

export interface NotificationsState {
  notifications: NotificationConfig[]
  orderFilters: NotificationOrderFilter[]
  notificationTemplates: NotificationTemplate[]
  errors: {
    createNotificationError: string | undefined
    updateNotificationStatusError: string | undefined
    updateNotificationError: string | undefined
    deleteNotificationError: string | undefined
  }
}

const initialState: NotificationsState = {
  notifications: [],
  orderFilters: [],
  notificationTemplates: [],
  errors: {
    createNotificationError: undefined,
    updateNotificationStatusError: undefined,
    updateNotificationError: undefined,
    deleteNotificationError: undefined,
  },
}

type ErrorKeys = 'createNotificationError' | 'updateNotificationStatusError' | 'updateNotificationError' | 'deleteNotificationError'

export const notificationsSlice = createSlice({
  name: 'notificationssStore',
  initialState,
  reducers: {
    setNotifications: (state, { payload }: PayloadAction<NotificationConfig[]>) => {
      state.notifications = payload
    },
    setNotificationsOrderFilters: (state, { payload }: PayloadAction<NotificationOrderFilter[]>) => {
      state.orderFilters = payload
    },
    setNotificationTemplates: (state, { payload }: PayloadAction<NotificationTemplate[]>) => {
      state.notificationTemplates = payload
    },
    setNotificationError: (state, { payload }: PayloadAction<{ errorKey: ErrorKeys; errorMessage: string | undefined }>) => {
      const defaultError = {
        createNotificationError: undefined,
        updateNotificationError: undefined,
        deleteNotificationError: undefined,
      }
      state.errors = {
        ...defaultError,
        ...state.errors,
        [payload.errorKey]: payload.errorMessage,
      }
    },
    reset: (state) => {
      state.notifications = []
      state.orderFilters = []
      state.notificationTemplates = []
      state.errors = {
        createNotificationError: undefined,
        updateNotificationStatusError: undefined,
        updateNotificationError: undefined,
        deleteNotificationError: undefined,
      }
    },
  },
})

export const { setNotifications, setNotificationsOrderFilters, setNotificationTemplates, setNotificationError, reset } =
  notificationsSlice.actions
export const { reducer } = notificationsSlice
