import { persistReducer } from 'redux-persist'
import Storage from 'redux-persist/lib/storage'
import { AnyAction, combineReducers } from 'redux'

import { reducer as authReducer } from './AuthStore/authSlice'
import { reducer as uiReducer } from './UiStore/uiSlice'
import { reducer as loadingReducer } from './LoadingStore/loadingSlice'
import { reducer as orderReducer } from './OrdersStore/ordersSlice'
import { reducer as notificationsReducer } from './NotificationsStore/notificationsSlice'
import { reducer as productReducer } from './ProductsStore/productsSlice'
import { reducer as userReducer } from './UserStore/userSlice'
import { reducer as newItemReducer } from './NewItemStore/newItemSlice'
import { reducer as bloomreachReducer } from './BloomReachStore/bloomreachSlice'
import { reducer as featureFlagReducer } from './FeatureFlagStore/featureFlagSlice'
import { reducer as compareProductsReducer } from './CompareProductsStore/compareProductsSlice'
import { reducer as wishlistReducer } from './WishlistStore/wishlistSlice'
import { reducer as dashboardReducer } from './DashboardStore/dashboardSlice'
import { reducer as siteReducer } from './SiteStore/siteSlice'

// b2b cart and checkout
import { reducer as b2bCartReducer } from './b2b/CartStore/cartSlice'
import { reducer as b2bCheckoutReducer } from './b2b/CheckoutStore/checkoutSlice'

// b2c cart and checkout
import { reducer as b2cCartReducer } from './b2c/CartStore/cartSlice'
import { reducer as b2cCheckoutReducer } from './b2c/CheckoutStore/checkoutSlice'
import { AppThunk } from './rootStore'

const rootPersistConfig = {
  key: 'root',
  storage: Storage,
  blacklist: ['orderReducer', 'productReducer', 'notificationsReducer', 'featureFlagReducer', 'b2bCheckoutReducer', ''],
}

const featureFlagsPersistConfig = {
  key: 'featureFlagReducer',
  storage: Storage,
  blacklist: ['orderReducer', 'productReducer', 'notificationsReducer'],
}

const ordersPersistConfig = {
  key: 'orderReducer',
  storage: Storage,
}
const notificationsPersistConfig = {
  key: 'notificationsReducer',
  storage: Storage,
}
const wishlistPersistConfig = {
  key: 'wishlistReducer',
  storage: Storage,
}

const productsPersistConfig = {
  key: 'productReducer',
  blacklist: ['productReducer'],
  storage: Storage,
}

const loadingPersistConfig = {
  key: 'loadingReducer',
  storage: Storage,
  blacklist: ['requests'],
}

const compareProductsPersistConfig = {
  key: 'compareProductsReducer',
  storage: Storage,
  blacklist: ['compareProductsReducer'],
}

const b2bCartPersistConfig = {
  key: 'b2bCartReducer',
  storage: Storage,
  // whitelist: ['cartId'],
  // blacklist: ['cartConfig'],
}

const b2bCheckoutPersistConfig = {
  key: 'b2bCheckoutReducer',
  storage: Storage,
  blacklist: ['b2bCheckoutReducer'],
}

const b2cCartPersistConfig = {
  key: 'b2cCartReducer',
  storage: Storage,
}

const b2cCheckoutPersistConfig = {
  key: 'b2cCheckoutReducer',
  storage: Storage,
}

const dashboardPersistConfig = {
  key: 'dashboardReducer',
  blacklist: ['dashboardReducer'],
  storage: Storage,
}

const sitePersistConfig = {
  key: 'siteReducer',
  storage: Storage,
}

const appReducer = combineReducers({
  authReducer,
  uiReducer,
  userReducer,
  newItemReducer,
  bloomreachReducer,
  loadingReducer: persistReducer(loadingPersistConfig, loadingReducer),
  orderReducer: persistReducer(ordersPersistConfig, orderReducer),
  notificationsReducer: persistReducer(notificationsPersistConfig, notificationsReducer),
  productReducer: persistReducer(productsPersistConfig, productReducer),
  featureFlagReducer: persistReducer(featureFlagsPersistConfig, featureFlagReducer),
  compareProductsReducer: persistReducer(compareProductsPersistConfig, compareProductsReducer),
  b2bCartReducer: persistReducer(b2bCartPersistConfig, b2bCartReducer),
  b2bCheckoutReducer: persistReducer(b2bCheckoutPersistConfig, b2bCheckoutReducer),
  b2cCartReducer: persistReducer(b2cCartPersistConfig, b2cCartReducer),
  b2cCheckoutReducer: persistReducer(b2cCheckoutPersistConfig, b2cCheckoutReducer),
  wishlistReducer: persistReducer(wishlistPersistConfig, wishlistReducer),
  dashboardReducer: persistReducer(dashboardPersistConfig, dashboardReducer),
  siteReducer: persistReducer(sitePersistConfig, siteReducer),
})

export const resetState = (): AppThunk => (dispatch) => {
  dispatch({ type: 'root/RESET' })
}

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
  if (action.type === 'root/RESET') {
    window.localStorage.removeItem('persist:loadingReducer')
    window.localStorage.removeItem('persist:userReducer')
    window.localStorage.removeItem('persist:authReducer')
    window.localStorage.removeItem('persist:orderReducer')
    window.localStorage.removeItem('persist:notificationsReducer')
    window.localStorage.removeItem('persist:productReducer')
    window.localStorage.removeItem('persist:featureFlagReducer')
    window.localStorage.removeItem('persist:b2bCartReducer')
    window.localStorage.removeItem('persist:b2bCheckoutReducer')
    window.localStorage.removeItem('persist:b2cCartReducer')
    window.localStorage.removeItem('persist:b2cCheckoutReducer')
    window.localStorage.removeItem('persist:wishlistReducer')
    window.localStorage.removeItem('persist:dashboardReducer')
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer)

export type RootState = ReturnType<typeof persistedReducer>
