import { ConfigRepository } from '../../repositories/ConfigRepository'
import { Requests } from '../../types/common'
import { addRequest, removeRequest } from '../LoadingStore/loadingSlice'
import { AppThunk } from '../rootStore'
import { setFeatureFlags } from './uiSlice'

export const getFeatureFlags = (): AppThunk => async (dispatch) => {
  try {
    dispatch(addRequest(Requests.getFeatureFlags))

    const configRepository = ConfigRepository.getInstance()

    const featureFlags = await configRepository.getFeatureFlags()
    if (featureFlags) {
      // for more efficient access later
      const formattedFeatureFlags = featureFlags.reduce<Record<string, boolean>>((acc, curr) => {
        acc[curr.name] = curr.enabled
        return acc
      }, {})

      dispatch(setFeatureFlags(formattedFeatureFlags))
    } else {
      dispatch(setFeatureFlags({}))
    }
  } catch (err) {
    console.log('[getFeatureFlags.error]', err)
  } finally {
    dispatch(removeRequest(Requests.getFeatureFlags))
  }
}
