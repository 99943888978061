const NotificationIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1030_7517)">
        <path
          d="M8 5.5H6C5.46957 5.5 4.96086 5.71071 4.58579 6.08579C4.21071 6.46086 4 6.96957 4 7.5V19.5C4 20.0304 4.21071 20.5391 4.58579 20.9142C4.96086 21.2893 5.46957 21.5 6 21.5H11.697"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M18 14.5V18.5H22" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path
          d="M18 11.5V7.5C18 6.96957 17.7893 6.46086 17.4142 6.08579C17.0391 5.71071 16.5304 5.5 16 5.5H14"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8 5.5C8 4.96957 8.21071 4.46086 8.58579 4.08579C8.96086 3.71071 9.46957 3.5 10 3.5H12C12.5304 3.5 13.0391 3.71071 13.4142 4.08579C13.7893 4.46086 14 4.96957 14 5.5C14 6.03043 13.7893 6.53914 13.4142 6.91421C13.0391 7.28929 12.5304 7.5 12 7.5H10C9.46957 7.5 8.96086 7.28929 8.58579 6.91421C8.21071 6.53914 8 6.03043 8 5.5Z"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14 18.5C14 19.5609 14.4214 20.5783 15.1716 21.3284C15.9217 22.0786 16.9391 22.5 18 22.5C19.0609 22.5 20.0783 22.0786 20.8284 21.3284C21.5786 20.5783 22 19.5609 22 18.5C22 17.4391 21.5786 16.4217 20.8284 15.6716C20.0783 14.9214 19.0609 14.5 18 14.5C16.9391 14.5 15.9217 14.9214 15.1716 15.6716C14.4214 16.4217 14 17.4391 14 18.5Z"
          stroke="black"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M8 11.5H12" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M8 15.5H11" stroke="black" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1030_7517">
          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export { NotificationIcon }
